import axios from "axios";
import { isSectionalConfigurator } from "../utils/supportUtils";
import { getAttribute, setAttributes } from "../utils/threekit/attributesFunctions";

const CONFIGURATIONS_API_ROUTE = `api/configurations`;

export function returnPathApi() {
  // const path = 'http://localhost:3000'
  const path = window.location.origin;
  return path;
}

export type modelDataT = {
  sku: string;
  title: string;
  price: {
    salePrice: number;
    msrp: number;
  };
};

function dataURItoBlob(dataURI: string) {
  var byteString = atob(dataURI.split(",")[1]);

  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  var ab = new ArrayBuffer(byteString.length);

  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

type savedConfigurationT = {
  metadata?: any;
  authToken: string;
  threekitUrl: string;
  orgId: string;
};

export const SavedConfiguration = async (props: savedConfigurationT) => {
  const { metadata, authToken, orgId, threekitUrl } = props;

  let message;
  if (!window.player.assetId) message = "Requires Asset Id";
  if (!window.configurator.getAttributes()) message = "Requires a configuration";
  if (!document.getElementById("player")) message = "Not canvas configuration";
  if (message) return [undefined, { message }];

  const prevCamera = getAttribute("ActiveCamera").value;

  setAttributes("ActiveCamera", "Perspective");

  // let canvas = <HTMLCanvasElement>document.getElementById("player");
  // const width: any =
  //   (await Number(canvas.offsetWidth)) > 500 ? Number(canvas.offsetWidth) * 0.75 : Number(canvas.offsetWidth);
  // const height: any =
  //   (await Number(canvas.offsetWidth)) > 500 ? Number(canvas.offsetHeight) * 0.75 : Number(canvas.offsetHeight);
  window.player.camera.frameBoundingSphere();
  window.player.camera.zoom(+3);

  const base64_small = await window.player.snapshotAsync({
    size: { width: 2560, height: 1080 },
  });

  const blob_small = await dataURItoBlob(base64_small);

  const file_small = await new File([blob_small], "snapshot.png");
  const configuration = window.playerT.configurator.getFullConfiguration();
  if (isSectionalConfigurator()) {
    const newPillowsArray: any[] = [];
    const oldPillows = metadata.threeKit.pillows;
    Object.keys(oldPillows).forEach((key, index) => {
      const pillow = oldPillows[key];
      const namePillow = window.player.scene.get({
        from: window.player.player.api.instanceId,
        id: pillow.id,
      });
      newPillowsArray.push({
        ...pillow,
        transform: namePillow.plugs.Transform,
      });
    });
    metadata["pillows_transform"] = newPillowsArray;
  }
  const pathName = window.location.pathname;
  metadata["pathname"] = pathName;

  const fd = await new FormData();
  await fd.append("files", file_small);
  await fd.append("productId", window.player.assetId);
  await fd.append("productVersion", "v1");
  await fd.append("orgId", orgId);
  await fd.append(
    "variant",
    // JSON.stringify(window.configurator.configuration()) // getFullConfiguration
    JSON.stringify(configuration)
  );
  if (metadata && Object.keys(metadata)) await fd.append("metadata", JSON.stringify(metadata));

  let response = await fetch(`${threekitUrl}${CONFIGURATIONS_API_ROUTE}?bearer_token=${authToken}`, {
    method: "post",
    body: fd,
  });
  setAttributes("ActiveCamera", prevCamera);

  const data = await response.json();

  return { ...data, file_small: base64_small };
};

export const getSavedConfiguration = async ({ ...props }) => {
  const { configurationId, authToken, threekitUrl } = props;

  let error;
  if (!configurationId) error = "Requires Configuration ID";
  if (error) return [undefined, { message: error }];

  let response = await axios
    .get(`${threekitUrl}${CONFIGURATIONS_API_ROUTE}/${configurationId}?bearer_token=${authToken}`)
    //@ts-ignore
    .then((response) => response);

  return response["data"];
};

type generateSnapshotT = {
  move: boolean;
  isSectional: boolean;
};

export const generateSnapshot = async ({
  // move,
  isSectional,
}: generateSnapshotT) => {
  setAttributes("showDimentions", false);
  const models = window.player.scene.get({ name: "Models", evalNode: true });
  // if (isSectional) {
  //   const boundingBox = getBoundingBox(models.id);
  //   const translation = getTranslaTion(models.id);
  //   const sizeModel = getSizeObject(boundingBox);

  //   setTranslation(models.id, {
  //     ...translation,
  //     x: translation.x - sizeModel.width / 2,
  //   });
  // }

  window.player.camera.frameBoundingSphere();
  window.player.selectionSet.clear();

  let canvas = <HTMLCanvasElement>document.getElementById("player");

  const width =
    (await Number(canvas.offsetWidth)) > 500 ? Number(canvas.offsetWidth) * 0.75 : Number(canvas.offsetWidth);
  const height =
    (await Number(canvas.offsetWidth)) > 500 ? Number(canvas.offsetHeight) * 0.75 : Number(canvas.offsetHeight);
  const base64_small: string = await window.player.snapshotAsync({
    size: { width, height: height },
  });

  const blob_small = await dataURItoBlob(base64_small);
  const file_small = await new File([blob_small], "snapshot.png");

  // isSectional && setTranslation(models.id, { x: 0, y: 0, z: 0 });

  return { file_small, blob_small, base64_small };
};
