import { useEffect } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";

import { RouterComponent } from "./RouterComponent";
import { InfoHorizentalMode } from "./components/InfoHorizentalMode/InfoHorizentalMode";
import { redirectToSavedConfigurator } from "./function/base/redirectToSavedConfigurator";
import { setActiveSubcategory, setEffectiveAccountId, setIsInStore, setUserType } from "./redux/features/ui/uiSlice";
import { getEffectiveAccountId } from "./redux/selectors/ui.selector";
import { useAppDispatch, useAppSelector } from "./utils/hooks";
declare global {
  interface Window {
    player: any;
    playerT: any;
    configurator: any;
    threekitPlayer: any;
    constantsUI: any;
  }
}
export const ConfiguratorApp = ({ ...props }: any) => {
  const effectiveAccountIdStore = useAppSelector(getEffectiveAccountId);
  redirectToSavedConfigurator();
  const dispatch = useAppDispatch();
  useEffect(() => {
    // Retrieve `effectiveAccountId` from URL search parameters
    const urlParams = new URLSearchParams(window.location.search);
    const effectiveAccountId = urlParams.get("effectiveAccountId") || urlParams.get("EffectiveAccountId") || "";
    const inStore = urlParams.get("inStore") === "true" || false;
    const subcategory = urlParams.get("subCategory") || "";
    const priceUserType = urlParams.get("userType")?.toLowerCase() === "wholesale" ? "wholesale" : "retail";

    dispatch(setIsInStore(inStore));
    dispatch(setUserType(priceUserType));

    if (subcategory) {
      dispatch(setActiveSubcategory(subcategory));
    }

    if (effectiveAccountId && effectiveAccountIdStore === "DEFAULT") {
      // Dispatch the action to set it in the Redux store
      dispatch(setEffectiveAccountId(effectiveAccountId.toUpperCase()));
    } else {
      console.warn("No effectiveAccountId found in the URL");
    }
  }, []);
  return (
    <>
      <>
        <InfoHorizentalMode />
        <RouterComponent />
      </>
      {/* 
      <iframe
        ref={inputEl}
        title="3kit_iframe"
        className="3kit_iframe"
        id="3kit_iframe"
        src={`http://localhost:3000`}
        style={{ height: "100vh", width: "100vw" }}
        frameBorder="0"
      ></iframe> */}
    </>
  );
};
