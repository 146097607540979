export function replaceSpacesWithUnderscore(str: string) {
  return str.split(" ").join("_");
}

export function addBeforePenultimateWord(str: string, newWord: string) {
  const words = str.split(" ");
  if (words.length < 2) {
    return str; // Если в строке меньше двух слов, возвращаем её без изменений
  }
  words.splice(words.length - 1, 0, newWord); // Добавляем новое слово перед последним
  return words.join(" ");
}
