import { filterFabricUI } from "../filterFabrics";

export const configurationBedroom = {
  objTabs: {
    Beds: [
      "accordionBedSize",
      "accordionHeadboardShapeHeight",
      "accordionTreatment",
      "accordionStorageOptions",
      "accordionLegStyleFinish",
      "accordionFabrics",
    ],
    "Headboards Only": [
      "accordionBedSizeForHeadboard",
      "accordionHeadboardShapeHeight",
      "accordionChooseTreatment",
      "accordionLegStyleFinish",
      "accordionFabrics",
    ],
  },
  accordions: {
    accordionLegStyleFinish: {
      type: "Accordion",
      data: {
        id: "accordionLegStyleFinish",
        label: "Choose Leg Style & Finish",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        embeddedView: [
          {
            type: "StandartOptionsWrap",
            data: {
              id: "leg",
              label: "Step 1. Choose Leg Style",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
          {
            type: "FinishGroup",
            data: {
              id: "leg_material",
              label: "Step 2. Choose Leg Finish",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionBedSize: {
      type: "Accordion",
      data: {
        id: "accordionBedSize",
        label: "Choose Bed Size",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        embeddedView: [
          {
            type: "StandartOptionsWrap",
            data: {
              id: "bed_size",
              label: "",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionHeadboardShapeHeight: {
      type: "Accordion",
      data: {
        id: "accordionHeadboardShapeHeight",
        label: "Choose Headboard shape & height",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        embeddedView: [
          {
            type: "StandartOptionsWrap",
            data: {
              id: "style",
              label: "Step 1. Choose Headboard Shape",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
          {
            type: "StandartOptionsWrap",
            data: {
              id: "height",
              label: "Step 2. Choose Headboard Height",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionTreatment: {
      type: "Accordion",
      data: {
        id: "accordionTreatment",
        label: "Headboard Treatment",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        embeddedView: [
          {
            type: "StandartOptionsWrap",
            data: {
              id: "treatment",
              label: "",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionFabrics: {
      type: "Accordion",
      data: {
        id: "accordionFurnitureFabrics",
        label: "Choose from over 500 fabrics",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        filter: filterFabricUI,
        embeddedView: [
          {
            type: "ImgRadio",
            data: {
              id: "fabric",
              label: "",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionFurnitureFabrics: {
      type: "Accordion",
      data: {
        id: "accordionFurnitureFabrics",
        label: "Furniture Fabrics",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        filter: filterFabricUI,
        embeddedView: [
          {
            type: "ImgRadio",
            data: {
              id: "fabric",
              label: "",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionBedSizeForHeadboard: {
      type: "Accordion",
      data: {
        id: "accordionBedSizeForHeadboard",
        label: "Headboard Treatment",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        embeddedView: [
          {
            type: "StandartOptionsWrap",
            data: {
              id: "bed_size",
              label: "",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionHeadbordShape: {
      type: "Accordion",
      data: {
        id: "accordionHeadbordShape",
        label: "Choose Headboard Shape",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        embeddedView: [
          {
            type: "StandartOptionsWrap",
            data: {
              id: "style",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionChooseTreatment: {
      type: "Accordion",
      data: {
        id: "accordionChooseTreatment",
        label: "Headboard Pattern",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        embeddedView: [
          {
            type: "StandartOptionsWrap",
            data: {
              id: "treatment",
              label: "",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionStorageOptions: {
      type: "Accordion",
      data: {
        id: "accordionStorageOptions",
        label: "Choose Storage Options",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        embeddedView: [
          {
            type: "StandartOptionsWrap",
            data: {
              id: "storage",
              label: "",
              typeOptions: "configurationOptions",
              values: [
                {
                  name: "No Drawer",
                  label: "No Drawer",
                  visible: true,
                  enabled: true,
                },
                {
                  name: "End Drawer",
                  label: "End Drawer",
                  visible: true,
                  enabled: true,
                },
              ],
              value: "No Drawer",
            },
          },
        ],
      },
    },
  },
};
